<template>
    <div 
        class="dependent" 
        v-if="!obj.dependent.hidden" 
        :class="obj.dependent.status ? 'enable' : 'disabled'"
    >     
        <img src="../../../../../assets/icons/financial-dependent.png" alt="" />
        Financeiro
        <img src="../../../../../assets/icons/arrow-down.png" :class="'arrow-disable'" alt="" v-if="obj.dependent.status && !visibleItem" />
        <img src="../../../../../assets/icons/arrow-up.png" :class="'arrow-disable'" alt="" v-if="obj.dependent.status && visibleItem" />
        <img src="../../../../../assets/icons/circle-inative.png" alt="" v-if="!obj.dependent.status" />
    </div>
</template>

<script>
import { ref } from "vue";

export default {
    props: ["obj", "visibleItem"],
    setup() {


        return {
        }
    }
}
</script>

<style lang="scss" scoped>
.dependent {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: max-content;
    gap: 8px;
    min-width: 103px;

    color: var(--gray-3, #828282);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

    user-select: none;
    cursor: pointer;

    &:hover {
        text-shadow: 2px 2px 4px rgb(130, 130, 130, 0.4);
    }
}

.enable {
    color: #828282;
    border: 1px solid #2D9CDB;
    gap: 12px;
    padding: 5px 14px;
    border-radius: 100px;
}

.disabled {
    color: #BDBDBD;
    border: 1px solid #BDBDBD;
    gap: 12px;
    padding: 5px 14px;
    border-radius: 100px;
    cursor: not-allowed;
}
.arrow-disable{
    filter: invert(75%) sepia(0%) saturate(0%) hue-rotate(346deg) brightness(101%) contrast(93%);
}
.arrow-disable{
    filter: invert(75%) sepia(0%) saturate(0%) hue-rotate(346deg) brightness(101%) contrast(93%);
}
</style>