import image3 from "../../../assets/avatares/dependents/3.png"
import image4 from "../../../assets/avatares/dependents/4.png"
import image5 from "../../../assets/avatares/dependents/5.png"
import image6 from "../../../assets/avatares/dependents/6.png"

export const dataRelatives = {
    group: 'Familiares',
    data: [
        {
            url: image3,
            name: "Camilla",
            sex: "F",
            status: "aprovado",
            dependent: {
                status: true,
                hidden: false,
                dependents: [
                    {
                        date: "22/06/2022",
                        exam: "Ginecologia geral rotineira",
                        professional: "Doutora Mariza Medeiros",
                    },
                    {
                        date: "15/06/2022",
                        exam: "Exames locais ortopédicos",
                        professional: "Doutor Carlos Quintana",
                    },
                    {
                        date: "01/06/2022",
                        exam: "Exames gerais de rotina",
                        professional: "Doutor Marcos Gomes",
                    },
                ]
            },
            status_service: {name: "registro", status: false},
            action: {name: "marcar", status: false}
        },
    ]
}

export const dataFriends = {
    group: 'Amigos',
    data: [
        {
            url: image4,
            name: "Gabrielly",
            sex: "F",
            status: "aprovado",
            dependent: {
                status: false,
                hidden: false,
                dependents: [
                    {
                        date: "22/06/2022",
                        exam: "Ginecologia geral rotineira",
                        professional: "Doutora Mariza Medeiros",
                    },
                    {
                        date: "14/05/2022",
                        exam: "Exames locais ortopédicos",
                        professional: "Doutor Carlos Quintana",
                    },
                ]
            },
            status_service: {name: "registro", status: true},
            action: {name: "marcar", status: false}
        },
        {
            url: image5,
            name: "Marcos",
            sex: "M",
            status: "aprovado",
            dependent: {
                status: false,
                hidden: false,
                dependents: []
            },
            status_service: {name: "registro", status: true},
            action: {name: "marcar", status: true}
        },
    ]
}